'use client';

import dynamic from 'next/dynamic';

export const DynamicScrollTopButton = dynamic(
  () => import(/* webpackChunkName: 'ScrollTopButton' */ './ScrollTopButton'),
  {
    loading: () => null,
    ssr: false,
  },
);
