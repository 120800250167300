'use client';

import dynamic from 'next/dynamic';

export const DynamicNewsletterSheet = dynamic(
  () =>
    import(
      /* webpackChunkName: 'CMS_NewsletterSheet' */ '@packages/cms-components/src/components/Forms/NewsletterSheet/NewsletterSheet'
    ),
  { loading: () => <span data-testid="newslettersheet" />, ssr: false },
);
