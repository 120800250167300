import(/* webpackMode: "eager", webpackExports: ["CmsContentTracking"] */ "/builds/empiriecom/core/frontend/turborepo/apps/shopping/src/app/[lang]/(shop)/(cms)/cms/[...cmsPage]/components/CmsContentTracking.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContentEffects"] */ "/builds/empiriecom/core/frontend/turborepo/apps/shopping/src/app/[lang]/(shop)/(cms)/cms/[...cmsPage]/components/ContentEffects.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Content"] */ "/builds/empiriecom/core/frontend/turborepo/packages/cms-components/src/components/CmsContent/Content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DynamicNewsletterSheet"] */ "/builds/empiriecom/core/frontend/turborepo/packages/cms-components/src/components/Forms/NewsletterSheet/NewsletterSheet.dynamic.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoaderSwrConfig"] */ "/builds/empiriecom/core/frontend/turborepo/packages/cms-components/src/components/LoaderSwrConfig/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SeoContent"] */ "/builds/empiriecom/core/frontend/turborepo/packages/cms-components/src/components/SeoContent/SeoContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ChatBot"] */ "/builds/empiriecom/core/frontend/turborepo/packages/cms-components/src/modules/ChatBot/ChatBot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PromotionBanner"] */ "/builds/empiriecom/core/frontend/turborepo/packages/cms-components/src/modules/PromotionBanner/PromotionBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageMetaData"] */ "/builds/empiriecom/core/frontend/turborepo/packages/modules/src/PageMetaData/PageMetaData.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DynamicScrollTopButton"] */ "/builds/empiriecom/core/frontend/turborepo/packages/modules/src/ScrollTopButton/ScrollTopButton.dynamic.tsx");
;
import(/* webpackMode: "eager" */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/components/ErrorBoundary/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["KimRating"] */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/components/KimRating/KimRating.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["nookieParseCookies","nookieSetCookie","nookieDestroyCookie"] */ "/builds/empiriecom/core/frontend/turborepo/packages/shared/src/providers/CookieProvider/CookieProvider.tsx");
