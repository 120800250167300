'use client';

import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Box } from '@packages/shared/src/components/Box/Box';
import { Typography } from '@packages/shared/src/components/Typography/Typography';
import { Collapse } from '@packages/shared/src/components/Collapse/Collapse';
import { Grid } from '@packages/shared/src/components/Grid/Grid';
import { Link } from '@packages/shared/src/components/Link/Link';
import type { SeoContentProps } from './types';
import { useAssignLinkTargetBasedOnBotStatus } from '../../hooks/useAssignLinkTargetBasedOnBotStatus';

const shoppingTipsMessages = defineMessages({
  headline: {
    id: 'seoContent.shoppingtips.heading',
    defaultMessage: 'Shopping Tipps',
  },
  showMore: {
    id: 'seoContent.shoppingtips.more',
    defaultMessage: '... mehr anzeigen',
  },
  showLess: {
    id: 'seoContent.shoppingtips.less',
    defaultMessage: '... weniger anzeigen',
  },
});

/**
 * Renders SEO relevant content ("shopping tips") consisting of links to
 * product lists.
 *
 * On mobile devices only the first one or two columns are shown and the others can
 * be opened by clicking on the button "..show more"
 */
export const SeoContent = ({ seoContent }: SeoContentProps) => {
  const intl = useIntl();
  const { isDesktop } = useDeviceType();
  const { linkGroups } = seoContent;
  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();

  // state of the collapse
  const [opened, setOpened] = useState(false);

  // how many entries does the largest group have?
  const maxElementsInGroup = linkGroups.reduce(
    (currentMax, currentGroup) =>
      currentGroup.links.length > currentMax ? currentGroup.links.length : currentMax,
    0,
  );

  // We need to know how high the collapse should be
  // 30px is the current static height per line and we multiply by maximum elements per group
  const collapsedHeight = maxElementsInGroup * 30;

  return (
    <Box sx={{ paddingTop: 7, paddingInline: { xs: 3, lg: 0 } }}>
      <Typography variant="h4" component="p" sx={{ marginBottom: 2 }}>
        {intl.formatMessage(shoppingTipsMessages.headline)}
      </Typography>
      <Collapse in={isDesktop ? true : opened} collapsedSize={collapsedHeight}>
        <Grid
          container
          columns={{ xs: 1, sm: 2, md: 2, lg: 6 }}
          spacing={7}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          {linkGroups.map((group, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={index} item sm={1} md={1}>
              {group.links.map((link) => {
                const finalLink = assignLinkTarget(link.url);
                return (
                  <Link
                    key={finalLink}
                    href={finalLink}
                    sx={{ display: 'block', marginBottom: 2, typography: 'body3' }}
                    color="inherit"
                  >
                    {link.alt}
                  </Link>
                );
              })}
            </Grid>
          ))}
        </Grid>
      </Collapse>

      {/* Button to open/close collapse (only mobile/tablet) */}
      {!isDesktop && (
        <Box
          sx={{ cursor: 'pointer', textDecoration: 'underline', marginTop: 2 }}
          role="button"
          onClick={() => setOpened((prev) => !prev)}
        >
          {intl.formatMessage(
            opened ? shoppingTipsMessages.showLess : shoppingTipsMessages.showMore,
          )}
        </Box>
      )}
    </Box>
  );
};
