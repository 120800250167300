'use client';

import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { useEffect } from 'react';

export const CmsContentTracking = ({ field_pagegroup }: { field_pagegroup: string }) => {
  const dispatchGTMEvent = useTracking();

  useEffect(() => {
    // dispatch information to fire landmark: 'http://localhost/servlet/11?clientId=1009&landmark=page&ssid=1009xxxxxxxxx&pc=storefront&pc2=homepage'
    dispatchGTMEvent({
      event: 'ga_event',
      eventValue: field_pagegroup,
      eventAction: 'view',
      eventCategory: field_pagegroup,
      eventLabel: 'inspire',
    });
  });

  return null;
};
